import _Input from 'antd/lib/input'
import styled from 'styled-components'
import { colors } from '../../vars'

export const InputContainer = styled.div``

export const Label = styled.span`
  color: ${colors.lightGrey};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
`

export const InputFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`

export const NumberInput = styled(_Input)`
  border: none;
  background-color: ${colors.veryLightGrey};
  border-radius: 4px;
  height: 42px;
  .ant-input {
    background-color: ${colors.veryLightGrey};
  }
`
