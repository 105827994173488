import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import _Input from 'antd/lib/input'
import { ReactSVG } from 'react-svg'

import { colors } from '../../vars'
import { InputContainer, Label, InputFieldWrapper, NumberInput  } from '../styledComponents'
import search from '../../images/icons/search.svg'
import camera from '../../images/icons/camera.svg'
import Button from 'antd/es/button'
import Tooltip from 'antd/lib/tooltip'
import Input from 'antd/lib/input'

interface Props {
  searchForPlate: (value: string) => void
  searchState: string | null
}

const CameraButton = styled(Button)`
  margin-left: 12px;
  flex-shrink: 0;
  &.ant-btn-lg {
    border-radius: 4px;
    height: 42px;
    width: 42px;
  }
`

const NumberPlateSearch: React.FunctionComponent<Props> = ({
  searchForPlate,
  searchState,
}) => {
  const [numberPlateValue, setNumberPlateValue] = useState<string>('')
  useEffect(() => {
    if (searchState === null) {
      setNumberPlateValue('')
      if (inputRef.current !== null) {
        inputRef.current.focus()
      }
    }
  }, [searchState])
  const inputRef = useRef<Input>(null)
  return (
    <InputContainer>
      <Label>Number Plate Code</Label>
      <InputFieldWrapper>
        <NumberInput
          placeholder="DEXX0000"
          ref={inputRef}
          autoFocus
          value={numberPlateValue}
          onChange={(e) => setNumberPlateValue(e.target.value)}
          onPressEnter={() => {
            searchForPlate(numberPlateValue)
            if (inputRef.current !== null) {
              inputRef.current.blur()
            }
          }}
          suffix={
            <ReactSVG
              onClick={() => searchForPlate(numberPlateValue)}
              src={search}
              style={{ height: 18, cursor: 'pointer' }}
            />
          }
        />
        <Tooltip
          placement="bottomRight"
          title="Use camera to scan plate (v2 feature)"
        >
          <CameraButton
            type="primary"
            icon={<ReactSVG src={camera} style={{ height: 24 }} />}
            size="large"
          />
        </Tooltip>
      </InputFieldWrapper>
    </InputContainer>
  )
}

export default NumberPlateSearch
