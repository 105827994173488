// Route for the border agent side of the app

import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import useAxios from 'axios-hooks'
import { message, Button } from 'antd'
import Select from 'react-select'
import countryList from 'react-select-country-list'

import AppHeader from '../components/header'
import {
  RouteContainer,
  NewButton,
  Divider,
  PassButton,
} from '../components/styled'
import NumberPlateSearch from '../components/agent/NumberPlateSearch'
import TransportInformation from '../components/agent/TransportInformation'
import { ICheckpoint } from '../api/types'
import { Disclaimer } from '../components/login'
import { InputContainer, Label, InputFieldWrapper, NumberInput  } from '../components/styledComponents'

import { API_URL } from '../consts/index';

interface Props extends RouteComponentProps {}

const AgentRoute: React.FunctionComponent<Props> = (props) => {
  const [searchState, setSearchState] = useState<string | null>(null)
  const [checkpointData, setCheckpointData] = useState<ICheckpoint>(null)
  const options = countryList().getData()
  const [countryObj, setCountryValue] = useState({ value: '' })

  const [
    { data: getData, loading: getLoading, error: getError },
    exectuteGet,
  ] = useAxios(
    `${API_URL}/checkpoints/${countryObj.value}/${searchState}`,
    { manual: true }
  )
  useEffect(() => {
    if (typeof searchState === 'string') {
      exectuteGet()
        .then(() => setCheckpointData(getData))
        .catch((err) => {
          message.error('No shipments found for number plate', 3)
        })
      console.log(getData)
    }
  }, [searchState])

  // const [
  //   { data: passData, loading: passLoading, error: passError },
  //   exectutePass,
  // ] = useAxios(
  //   {
  //     url: `https://euvsvirus-borderx.herokuapp.com/api/checkpoints/RO/${searchState}`,
  //     method: 'POST',
  //   },
  //   { manual: true }
  // )
  return (
    <RouteContainer>
      <AppHeader />
      <InputContainer>
        <Label>Country</Label>
        <Select
          options={options}
          value={countryObj}
          onChange={setCountryValue}
        />
      </InputContainer>
      <NumberPlateSearch
        searchState={searchState}
        searchForPlate={(value) => {
          setSearchState(value)
        }}
      />
      {!getError && searchState ? (
        <>
          <Divider />
          <TransportInformation
            loading={getLoading}
            checkpointData={{
              ...getData,
              firstBorder: true,
              priority: 1,
            }}
          />
          {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              danger
              onClick={() => exectutePass({ data: { pass: false } })}
            >
              Reject transport
            </Button>
            <PassButton onClick={() => exectutePass({ data: { pass: true } })}>
              Allow transport
            </PassButton>
          </div> */}

          <NewButton
            onClick={() => setSearchState(null)}
            size="large"
            block
            type="primary"
          >
            New search
          </NewButton>
        </>
      ) : (
        <Disclaimer visibleOnMobile>
          The number plate lookup is linked to our API.

          <br /> Use one of these example number plates to get a result:
          <table style={{ width: '100%' }}>
            <tr>
              <td><b>Romaina</b></td>
              <td><b>1-TEST-158-641</b></td>
            </tr>
            <tr>
              <td><b>Austria</b></td>
              <td><b>1-TEST-277-367</b></td>
            </tr>
          </table>
        </Disclaimer>
      )}
    </RouteContainer>
  )
}

export default AgentRoute
