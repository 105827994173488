// Universal component for the Header of the BorderX app (Agent and Carrier)

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import Tooltip from 'antd/lib/tooltip'

import logo from '../images/BorderX_logo.svg'
import user from '../images/icons/user.svg'
import key from '../images/icons/key.svg'
import settings from '../images/icons/settings.svg'
import { dimensions } from '../vars'

interface Props {
  showKeysMenu?: boolean
}

const Header = styled.nav`
  height: ${dimensions.headerHeight}px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
`
const Logo = styled.img``
const SymbolNav = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100px;
  cursor: not-allowed;
`
const AppHeader: React.FunctionComponent<Props> = ({ showKeysMenu }) => {
  return (
    <Header>
      <Link to="/">
        <Logo src={logo} />
      </Link>
      <Tooltip
        placement="bottomRight"
        title="User Profile and Settings (v2 feature)"
      >
        <SymbolNav>
          <ReactSVG
            style={{ height: 28, transform: 'scale(1.05)' }}
            src={user}
          />

          {showKeysMenu ? (
            <ReactSVG
              style={{ height: 28, transform: 'scale(1.05)' }}
              src={key}
            />
          ) : (
            <ReactSVG
              style={{ height: 28, transform: 'scale(1.05)' }}
              src={settings}
            />
          )}
        </SymbolNav>
      </Tooltip>
    </Header>
  )
}

export default AppHeader
