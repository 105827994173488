// Route for the login screen

import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { RouteContainer } from '../components/styled'
import Login from '../components/login'

interface Props extends RouteComponentProps {}

const LoginRoute: React.FunctionComponent<Props> = (props) => {
  return (
    <RouteContainer styleOverrides={{ padding: 0 }}>
      <Login />
    </RouteContainer>
  )
}

export default LoginRoute
