// Route for details of one shipment, can be accredited

import React, { useState, useEffect } from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import AppHeader from '../../components/header'
import { RouteContainer } from '../../components/styled'
import ShipmentDetail from '../../components/accreditation/shipmentDetail'
import { API_URL } from '../../consts/index';

interface Props extends RouteComponentProps {}

const AccreditationDetailRoute: React.FunctionComponent<Props> = (props) => {
  const { slug } = useParams()
  const [shipmentData, setShipmentData] = useState({})
  const [
    { response, data: getData, loading: getLoading, error: getError },
    exectuteGet,
  ] = useAxios(
    `${API_URL}/declarations/${slug}`,
    { manual: true }
  )

  useEffect(() => {
    exectuteGet()
      .then(() => setShipmentData(getData))
      .catch((err) => {
        console.log('ERRoR', err)
      })
  }, [])
  console.log('AccreditationDetailRoute', shipmentData, getData)
  // slug is the shipmentId
  return (
    <RouteContainer>
      <AppHeader showKeysMenu />
      <ShipmentDetail shipmentId={slug} shipmentData={getData} />
    </RouteContainer>
  )
}

export default AccreditationDetailRoute
