import React, { useState, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'
import { EllipsisOutlined, ArrowRightOutlined } from '@ant-design/icons'

import IconTitle from '../iconTitle'
import shipmentActive from '../../images/icons/shipment-active.svg'
import { colors } from '../../vars'

interface Props {
  from: string
  to: string
  noDots?: boolean
}

const Card = styled.div`
  display: flex;
  box-shadow: 0px 2px 16px rgba(33, 43, 54, 0.08),
    0px 1px 3px rgba(66, 66, 69, 0.12), 0px 0px 0px rgba(6, 44, 82, 0.1);
  border-radius: 4px;
  background: #fff;
  padding: 15px 17px;
  box-sizing: border-box;
  margin: 6px 0;
  justify-content: space-between;
  cursor: pointer;
`
const Route = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  color: #000 !important;
`
const Country = styled.span`
  width: 70px;
`

const ShipmentCard: React.FunctionComponent<Props> = ({ to, from, noDots }) => {
  return (
    <Card>
      <Route>
        <Country>{from}</Country>
        <ArrowRightOutlined style={{ flex: 1 }} />
        <Country>{to}</Country>
      </Route>
      {!noDots && <EllipsisOutlined style={{ transform: 'rotate(90deg)' }} />}
    </Card>
  )
}

export default ShipmentCard
