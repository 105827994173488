// Component for displaying active shipment

import React from 'react'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import Tag from 'antd/lib/tag'
import { Link, useParams } from 'react-router-dom'

import dotsDepatureActive from '../../images/icons/dots_depature_active.svg'
import dotsDestinationInactive from '../../images/icons/dots_destination_inactive.svg'
import dotsStopCurrent from '../../images/icons/dots_stop_current.svg'
import dotsStopInactive from '../../images/icons/dots_stop_inactive.svg'
import shipmentActive from '../../images/icons/shipment-active.svg'
import { colors } from '../../vars'
import IconTitle from '../iconTitle'
import { Divider, NewButton } from '../styled'

interface Props {
  shipmentData?: any
}

const ShipmentWrapper = styled.div``

const ShipmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 55px;
`
const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 8px 0;
  line-height: 0.8;
  color: ${colors.veryDarkGrey};
`

const ShipmentId = styled.span`
  color: ${colors.lightGrey};
  font-size: 14px;
`

const Tags = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
`

const ShipmentRoute = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`
const Origin = styled.div`
  display: flex;
`
const Destination = styled.div`
  display: flex;
  align-items: flex-start;
`
const Label = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.lightGrey};
`
const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Location = styled.span`
  font-weight: bold;
  font-size: 18px;
`
export const Dots = styled.img<{ active?: boolean }>`
  padding-top: ${(p) => (p.active ? 0 : 6)}px;
  margin-right: ${(p) => (p.active ? 16 : 20)}px;
  padding-left: ${(p) => (p.active ? 0 : 4)}px;
`
const RouteStops = styled.div``
const Stop = styled.span`
  display: flex;
`

const Shipment: React.FunctionComponent<Props> = ({ shipmentData }) => {
  return (
    <ShipmentWrapper>
      <IconTitle
        icon={
          <ReactSVG
            src={shipmentActive}
            style={{ height: 20 }}
            color={colors.green}
          />
        }
      >
        Active shipment
      </IconTitle>
      <ShipmentHeader>
        <Info>
          <Title>Shipment 1</Title>
          <ShipmentId>#3532-24</ShipmentId>
        </Info>
        <Tags>
          <Tag color="green">Accredited</Tag>
          <Tag color="green">High Priority</Tag>
        </Tags>
      </ShipmentHeader>
      <Divider />
      <ShipmentRoute>
        <Origin>
          <Dots src={dotsDepatureActive} />
          <LocationWrapper>
            <Label>Depature</Label>
            <Location>Milan, Italy</Location>
          </LocationWrapper>
        </Origin>
        <RouteStops>
          <Stop>
            <Dots active src={dotsStopCurrent} />
            Switzerland
          </Stop>
          <Stop>
            <Dots src={dotsStopInactive} />
            Austria
          </Stop>
          <Stop>
            <Dots src={dotsStopInactive} />
            Germany
          </Stop>
        </RouteStops>
        <Destination>
          <Dots src={dotsDestinationInactive} />
          <LocationWrapper>
            <Label>Destination</Label>
            <Location>Prague, Czechia</Location>
          </LocationWrapper>
        </Destination>
      </ShipmentRoute>
      <Link to="/carrier/createShipment">
        <NewButton block type="primary">
          Create new shipment
        </NewButton>
      </Link>
    </ShipmentWrapper>
  )
}

export default Shipment
