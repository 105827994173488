import styled, { CSSObject } from 'styled-components'
import { dimensions, colors } from '../vars'
import _Button from 'antd/lib/button'

export const RouteContainer = styled.section<{ styleOverrides?: CSSObject }>`
  margin: 0 auto;
  padding: 0 25px;
  max-width: ${dimensions.appMaxWidth}px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  ${(p) => p.styleOverrides}
`

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #666;
  opacity: 0.2;
  margin: 24px 0 30px;
`

export const NewButton = styled(_Button)`
  margin-top: auto;
  margin-bottom: 25px;
  border-radius: 4px;
  height: 50px;
  &.ant-btn-primary {
    background-color: ${colors.primaryBlue};
  }
`

export const PassButton = styled(NewButton)`
  background-color: ${colors.green};
  color: white;
  border: none;
  &:focus {
    background-color: ${colors.green};
    color: white;
  }
`
