// Route for the accreditation organization side of the app

import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import useAxios from 'axios-hooks'

import AppHeader from '../../components/header'
import { RouteContainer } from '../../components/styled'
import Shipments from '../../components/accreditation/shipments'

import { API_URL } from '../../consts/index';

interface Props extends RouteComponentProps {}

const AccreditationRoute: React.FunctionComponent<Props> = (props) => {
  const [shipmentData, setShipmentData] = useState([])
  const [
    { response, data: getData, loading: getLoading, error: getError },
    exectuteGet,
  ] = useAxios(
    `${API_URL}/declarations`,
    { manual: true }
  )

  useEffect(() => {
    exectuteGet()
      .then(({ data }) => setShipmentData(data))
      .catch((err) => {
        console.log('ERRoR', err)
      })
  }, [])
  return (
    <RouteContainer>
      <AppHeader showKeysMenu />
      <Shipments shipmentData={shipmentData} />
    </RouteContainer>
  )
}

export default AccreditationRoute
