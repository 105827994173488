// Component for the Number Plate search result

import React from 'react'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import _Card from 'antd/lib/card'

import IconTitle from '../iconTitle'
import shipment from '../../images/icons/shipment.svg'
import arrow from '../../images/icons/arrow.svg'
import ShipmentCheck, { CheckType } from './ShipmentCheck'
import { colors } from '../../vars'
import { ICheckpoint } from '../../api/types'

interface Props {
  loading?: boolean
  checkpointData: ICheckpoint
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

const TransportCard = styled(_Card)`
  box-shadow: 0px 2px 16px rgba(33, 43, 54, 0.08),
    0px 1px 3px rgba(66, 66, 69, 0.12), 0px 0px 0px rgba(6, 44, 82, 0.1);
  border-radius: 4px;
  border: none;
  .ant-card-loading-content {
    padding: 24px;
  }
  .ant-card-body {
    padding: 0;
  }
`
const CardBody = styled.div`
  padding: 24px 24px 10px;
  border-radius: 4px;
`

const AlertBanner = styled.div<{ background: string }>`
  min-height: 40px;
  height: 100%;
  padding: 10px 25px 0;
  box-sizing: border-box;
  background: ${(p) => p.background && p.background};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 4px 4px 0px 0px;
`

const ShipmentChecks = styled.div`
  display: flex;
  flex-direction: column;
`

const CardFooter = styled.div`
  height: 60px;
  padding: 0 24px;
  border-top: 1px solid rgba(102, 102, 102, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DestinationLabelBox = styled.div`
  display: flex;
  align-items: center;
`
const DestinationLabel = styled.span`
  font-size: 14px;
  margin-left: 8px;
  text-transform: uppercase;
  color: ${colors.primaryBlue};
`

const Destination = styled.span<{ checkType: CheckType }>`
  font-weight: 500;
  font-size: 14px;
  color: ${(p) => {
    if (p.checkType === CheckType.success) {
      return colors.green
    } else if (p.checkType === CheckType.neutral) {
      return colors.veryDarkGrey
    } else {
      return colors.red
    }
  }};
`

const AlertLevel = [colors.green, colors.orange, colors.red]

const getAlertLevel = (checkpointData: Props['checkpointData']): number => {
  if (!checkpointData.firstBorder || checkpointData.priority !== 1) {
    return 2
  }
  if (!checkpointData.firstBorder && checkpointData.priority === 1) {
    return 2
  }

  if (!checkpointData.accredited || !checkpointData.goodsStatusMatch) {
    return 1
  }
  if (checkpointData.accredited && !checkpointData.goodsStatusMatch) {
    return 0
  }
  return 0
}

const TransportInformation: React.FunctionComponent<Props> = ({
  checkpointData,
  loading,
}) => {
  return (
    <Container>
      <IconTitle icon={<ReactSVG src={shipment} style={{ height: 20 }} />}>
        Transport Information
      </IconTitle>
      <TransportCard loading={loading}>
        <AlertBanner background={AlertLevel[getAlertLevel(checkpointData)]}>
          {!checkpointData.firstBorder && (
            <ShipmentCheck isWhite checkType={CheckType.alert}>
              Multiple border crossings detected
            </ShipmentCheck>
          )}
          {checkpointData.firstBorder && checkpointData.priority !== 1 && (
            <ShipmentCheck isWhite checkType={CheckType.alert}>
              Priority declaration missing
            </ShipmentCheck>
          )}
          {checkpointData.priority === 1 && checkpointData.firstBorder && (
            <ShipmentCheck isWhite checkType={CheckType.success}>
              High priority declared
            </ShipmentCheck>
          )}
        </AlertBanner>
        <CardBody>
          <ShipmentChecks>
            <ShipmentCheck
              checkType={
                !checkpointData.accredited ? CheckType.alert : CheckType.success
              }
            >
              {checkpointData.accredited ? 'Accredited' : 'Not accredited'}
            </ShipmentCheck>
            <ShipmentCheck
              checkType={
                !checkpointData.goodsStatusMatch
                  ? CheckType.alert
                  : CheckType.success
              }
            >
              {checkpointData.goodsStatusMatch
                ? 'Signals are matching guidelines'
                : 'Signals do not match guidelines'}
            </ShipmentCheck>
          </ShipmentChecks>
        </CardBody>
        <CardFooter>
          <DestinationLabelBox>
            <ReactSVG style={{ height: 24 }} src={arrow} />
            <DestinationLabel>Destination</DestinationLabel>
          </DestinationLabelBox>
          <Destination checkType={CheckType.neutral}>
            {checkpointData.firstBorder ? 'Another country' : 'This country'}
          </Destination>
        </CardFooter>
      </TransportCard>
    </Container>
  )
}

export default TransportInformation
