// The main component of the login page

import React from 'react'
import styled from 'styled-components'
import Button from 'antd/lib/button'

import logoWhite from '../../images/BorderX_logo_white.svg'
import { Link } from 'react-router-dom'
import { colors, dimensions } from '../../vars'

interface Props {}

const LogoBox = styled.div`
  width: 100%;
  height: 50%;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    #a5fecb -33.93%,
    #20bdff 32.38%,
    #5433ff 102.98%
  );
`
const WhiteLogo = styled.img`
  height: 50px;
`
const LoginBox = styled.div`
  background-color: #fff;
  margin-top: -20px;
  padding: 35px 25px;
  width: 100%;
  height: 50%;
  max-height: 450px;

  @media screen and (max-width: ${dimensions.appMaxWidth}px) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`
const LoginTitle = styled.h1`
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 70px 0;
  color: ${colors.veryDarkGrey};
`
const LoginButton = styled(Button)`
  height: 50px;
  margin: 16px 0;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  &.ant-btn-primary {
    background-color: ${colors.primaryBlue};
  }
`

const ToDevpost = styled.a`
  text-decoration: none;
  font-weight: 300;
  font-size: 14px;
  color: ${colors.primaryBlue};
  text-align: center;
  margin: 20px auto 0;
  display: block;
`
export const Disclaimer = styled.span<{ visibleOnMobile?: boolean }>`
  font-weight: 300;
  font-size: 14px;
  color: ${colors.lightGrey};
  text-align: center;
  margin: 10px auto 0;
  display: block;
  @media screen and (max-width: ${dimensions.appMaxWidth}px) {
    display: ${(p) => (p.visibleOnMobile ? 'block' : 'none')};
  }
`

const Login: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <LogoBox>
        <WhiteLogo src={logoWhite} />
      </LogoBox>
      <LoginBox>
        <LoginTitle>Log In</LoginTitle>
        <Link to="/agent">
          <LoginButton block type="primary">
            Login as Border Agent
          </LoginButton>
        </Link>
        <Link to="/carrier">
          <LoginButton block type="primary">
            Login as Shipment Carrier
          </LoginButton>
        </Link>
        <Link to="/accreditation">
          <LoginButton block type="primary">
            Login as Accreditation Organization
          </LoginButton>
        </Link>
        <ToDevpost target="_blank" href="https://devpost.com/software/borderx">
          Visit project page on Devpost
        </ToDevpost>
        <Disclaimer>
          For the best experience, open this webapp on your phone.
        </Disclaimer>
      </LoginBox>
    </>
  )
}

export default Login
