import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import { ReactSortable } from 'react-sortablejs'
import { PlusOutlined } from '@ant-design/icons'
import useAxios from 'axios-hooks'
import countryList from 'react-select-country-list'

import shipmentActive from '../../images/icons/shipment-active.svg'
import dotsShort from '../../images/icons/dots_short.svg'
import dotsDestination from '../../images/icons/dots_destination.svg'
import mover from '../../images/icons/mover.svg'
import x from '../../images/icons/x.svg'
import { colors } from '../../vars'
import IconTitle from '../iconTitle'
import { Dots } from './shipment'
import { Input, Button, Checkbox, Tooltip } from 'antd'
import AgencySelector from './agencySelector'
import { NewButton } from '../styled'
import { Link, useHistory } from 'react-router-dom'
import { API_URL } from '../../consts/index';

interface Props {
  shipmentData?: JSON
}

interface ItemType {
  id: number
  country: string
}

const ShipmentCreationWrapper = styled.div``

const PassingCountriesList = styled(ReactSortable)``

const PassingCountry = styled.div`
  display: flex;
`
const DestinationCountry = styled.div`
  display: flex;
`

const PassingCountryInput = styled(Input)`
  height: 38px;
  width: 100%;
  margin-top: -9px;
  border-radius: 5px;
  border: 1px solid rgba(102, 102, 102, 0.24);
`

export const AddButton = styled(Button)`
  height: 38px;
  margin-top: -9px;
  background: ${colors.veryLightGrey} !important;
  text-align: left;
  border-radius: 5px;
  font-weight: 300;
  font-size: 14px;
`

const PriorityDeclarationWrapper = styled.div`
  margin-top: 80px;
  padding: 26px 0;
  border-top: 1px solid ${colors.veryLightGrey};
  border-bottom: 1px solid ${colors.veryLightGrey};
  span {
    font-weight: 500;
    font-size: 15px;
    color: #000;
  }
`

const ShipmentCreation: React.FunctionComponent<Props> = () => {
  const [passingCountries, setPassingCountries] = useState<ItemType[]>([{ country: '', id: 999 }])
  const [isAddingPassingCountry, setIsAddingPassingCountry] = useState(false)
  const [
    addPassingCountryInputValue,
    setAddPassingCountryInputValue,
  ] = useState('')
  const [priority, setPriority] = useState(false) 
  const [agencySelector, setAgencySelector] = useState(false)
  const [selectedAgency, setSelectedAgency] = useState<string | null>(null)
  const [
    { response, data: getData, loading: getLoading, error: getError },
    exectutePost,
  ] = useAxios({
      url: `${API_URL}/declarations`,
      method: 'POST'
    },
    { manual: true }
  )
  const history = useHistory();
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const submitData = () => {
    exectutePost({
      data: {
        licensePlate: `1-TEST-${getRandomInt(999)}-${getRandomInt(999)}`,
        priority: priority ? 1 : 0,
        // "senderVat": "NL12345678",
        // "consigneeVat": "IT87654321",
        // "carrierVat": "FR12345678",
        status: 'OPEN',
        countries:
          passingCountries.map(countryObj =>
            ({ iso2: countryList().getValue(countryObj.country) })
          )
      }
    }).then(() => {
      history.push('/carrier')
    })
  }
  const changeDestination = (value, index) => {
    const newPassingCountries = passingCountries.slice(0);
    newPassingCountries[index].country = value;
    setPassingCountries(newPassingCountries);
  }
  return (
    <>
      <ShipmentCreationWrapper>
        <IconTitle
          icon={
            <ReactSVG
              src={shipmentActive}
              style={{ height: 20 }}
              color={colors.green}
            />
          }
        >
          Create shipment
        </IconTitle>
        <PassingCountriesList
          list={passingCountries}
          setList={setPassingCountries}
        >
          {passingCountries.slice(0, passingCountries.length -1).map((item, key) => (
            <PassingCountry key={item.id}>
              <Dots src={dotsShort} />
              <PassingCountryInput
                prefix={<ReactSVG style={{ height: 14 }} src={mover} />}
                suffix={<ReactSVG style={{ height: 14 }} src={x} />}
                key={item.id}
                name={item.id.toString()}
                value={item.country}
                onChange={(e) => changeDestination(e.target.value, key)}
              />
            </PassingCountry>
          ))}
        </PassingCountriesList>

        <PassingCountry>
          <Dots src={dotsShort} />
          {false ? (
            <form
              style={{ marginTop: -9, width: '100%' }}
              onSubmit={(e) => {
                e.preventDefault()
                setPassingCountries([
                  {
                    id: passingCountries.length + 2,
                    country: addPassingCountryInputValue,
                  },
                  ...passingCountries,
                ])
                setAddPassingCountryInputValue('')
              }}
            >
              <PassingCountryInput
                autoFocus
                onChange={(e) => setAddPassingCountryInputValue(e.target.value)}
                value={addPassingCountryInputValue}
                suffix={
                  <ReactSVG
                    onClick={() => setIsAddingPassingCountry(false)}
                    style={{ height: 14, cursor: 'pointer' }}
                    src={x}
                  />
                }
              />
            </form>
          ) : (null)}

          <AddButton
              block
              onClick={() =>
                setPassingCountries([
                  ...passingCountries.slice(0, passingCountries.length -1),
                  {
                    id: passingCountries.length + 2,
                    country: '',
                  },
                  ...passingCountries.slice(passingCountries.length - 1),
                ])}
              icon={<PlusOutlined />}
            >
              Add passing country
          </AddButton>
        </PassingCountry>
        <DestinationCountry>
          <Dots active src={dotsDestination} />
          <PassingCountryInput
            placeholder="Destination country"
            onChange={(e) => changeDestination(e.target.value, passingCountries.length -1)}  />
        </DestinationCountry>
      </ShipmentCreationWrapper>
      <PriorityDeclarationWrapper>
        <Checkbox checked={priority} onChange={() => setPriority(!priority)}>
          <span>Shipment has high priority</span>
        </Checkbox>
      </PriorityDeclarationWrapper>
      <AgencySelector
        agencySelector={agencySelector}
        setAgencySelector={setAgencySelector}
        selectedAgency={selectedAgency}
        setSelectedAgency={setSelectedAgency} />
      {/* <Link to="/carrier"> */}
        <NewButton block type="primary" onClick={() => submitData()}>
          Add Shipment
        </NewButton>
      {/* </Link> */}
      <Link to="/carrier">
        <Button block type="link">
          Cancel
        </Button>
      </Link>
    </>
  )
}

export default ShipmentCreation
