// Route for the creation of shipments as a carrier

import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import AppHeader from '../../components/header'
import { RouteContainer } from '../../components/styled'
import ShipmentCreation from '../../components/carrier/shipmentCreation'

interface Props extends RouteComponentProps {}

const CreateShipmentRoute: React.FunctionComponent<Props> = (props) => {
  return (
    <RouteContainer>
      <AppHeader />
      <ShipmentCreation />
    </RouteContainer>
  )
}

export default CreateShipmentRoute
