// Title component for sections in the app

import React from 'react'
import styled from 'styled-components'

import { colors } from '../vars'

interface Props {
  icon?: JSX.Element
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.16px;
  margin-top: 0;
  margin-bottom: 0;
  color: ${colors.veryDarkGrey};
`

const IconTitle: React.FunctionComponent<Props> = ({ icon, children }) => {
  console.log(icon)

  return (
    <TitleWrapper>
      {icon}
      <Title
        style={icon === undefined ? { marginLeft: 0 } : { marginLeft: 12 }}
      >
        {children}
      </Title>
    </TitleWrapper>
  )
}

export default IconTitle
