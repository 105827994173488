export const colors = {
  background: '#FDFDFD',
  green: '#00C48C',
  orange: '#FFA26B',
  red: '#FF634E',
  primaryBlue: '#0084F4',
  darkGrey: '#666666',
  lightGrey: '#828282',
  veryLightGrey: '#F2F2F2',
  veryDarkGrey: '#333333',
}

export const dimensions = {
  appMaxWidth: 420,
  appMaxHeight: 850,
  headerHeight: 75,
}
