// Route for the shipment carrier side of the app

import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import AppHeader from '../../components/header'
import { RouteContainer } from '../../components/styled'
import Shipment from '../../components/carrier/shipment'

interface Props extends RouteComponentProps {}

const CarrierRoute: React.FunctionComponent<Props> = (props) => {
  return (
    <RouteContainer>
      <AppHeader />
      <Shipment />
    </RouteContainer>
  )
}

export default CarrierRoute
