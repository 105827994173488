import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'

import IconTitle from '../iconTitle'
import { colors } from '../../vars'
import { AddButton } from './shipmentCreation'
import { Button, Input } from 'antd'

// interface Props {
//   shipmentData?: JSON
// }

const AgencySelectorWrapper = styled.div`
  padding: 26px 0;
`

const AgencySelectorPopup = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: ${colors.veryLightGrey};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 30px 25px;
  z-index: 5;
`

const Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
`

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const CancelButton = styled(Button)`
  color: ${colors.primaryBlue};
  font-weight: 500;
  font-size: 15px;
`

const AgencySearch = styled.div``

const RecentAgencies = styled.div`
  margin-top: 24px;
`
const AgencyList = styled.div`
  display: flex;
  flex-direction: column;
`
const Agency = styled.div`
  background: #fff;
  padding: 12px 20px;
  box-sizing: border-box;
  margin: 4px 0;
  font-weight: 300;
  font-size: 13px;
  cursor: pointer;
`

const CurrentAgency = styled.div`
  border-radius: 5px;
  padding: 9px 18px;
  background: ${colors.veryLightGrey};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.veryLightGrey};
`

const AgencySelector = ({
  agencySelector, setAgencySelector, selectedAgency, setSelectedAgency
}) => {
  // const [agencySelector, setAgencySelector] = useState(false)
  // const [selectedAgency, setSelectedAgency] = useState<string | null>(null)
  // TODO: Make agency selection more specific by using some identifier instead of just the name
  useEffect(() => {
    if (selectedAgency !== null) {
      setAgencySelector(false)
    }
  }, [selectedAgency])
  return (
    <>
      <AgencySelectorWrapper>
        <IconTitle>Agency for accreditation</IconTitle>
        {selectedAgency === null ? (
          <AddButton
            block
            onClick={() => setAgencySelector(true)}
            icon={<PlusOutlined color={colors.veryLightGrey} />}
          >
            Add agency for accreditation
          </AddButton>
        ) : (
          <CurrentAgency onClick={() => setAgencySelector(true)}>
            {selectedAgency}
            <EllipsisOutlined style={{ transform: 'rotate(90deg)' }} />
          </CurrentAgency>
        )}
      </AgencySelectorWrapper>
      {agencySelector && (
        <>
          <Backdrop />
          <AgencySelectorPopup>
            <PopupHeader>
              <IconTitle>Select Agency</IconTitle>
              <CancelButton
                type="link"
                onClick={() => setAgencySelector(false)}
              >
                Cancel
              </CancelButton>
            </PopupHeader>
            <AgencySearch>
              <Input.Search allowClear placeholder="Search Agencies" />
            </AgencySearch>
            <RecentAgencies>
              <h3>Recent Agencies</h3>
              <AgencyList>
                <Agency onClick={() => setSelectedAgency('TransFix GmbH')}>
                  TransFix GmbH
                </Agency>
                <Agency onClick={() => setSelectedAgency('InterShip Intl.')}>
                  InterShip Intl.
                </Agency>
              </AgencyList>
            </RecentAgencies>
          </AgencySelectorPopup>
        </>
      )}
    </>
  )
}

export default AgencySelector
