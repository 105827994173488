// A data entry for a shipment

import React from 'react'
import styled from 'styled-components'
import { colors } from '../../vars'
import { ReactSVG } from 'react-svg'

import alertIcon from '../../images/icons/alertIcon.svg'
import failIcon from '../../images/icons/failIcon.svg'
import successIcon from '../../images/icons/successIcon.svg'

export enum CheckType {
  alert,
  success,
  fail,
  neutral,
}

interface Props {
  checkType: CheckType
  isWhite?: boolean
}

const CheckWrapper = styled.div<{ isWhite: Props['isWhite'] }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => (p.isWhite ? 10 : 20)}px;
`

const CheckText = styled.span<{
  checkType: CheckType
  isWhite: Props['isWhite']
}>`
  font-size: 14px;
  margin-left: 14px;
  color: ${(p) => (p.isWhite ? '#fff' : colors.veryDarkGrey)};
  font-weight: ${(p) => (p.checkType === CheckType.alert ? '500' : 'normal')};
`

const iconStyle = { height: 20 }

const ShipmentCheck: React.FunctionComponent<Props> = ({
  checkType,
  children,
  isWhite,
}) => {
  const getCheckIcon = (checkType: CheckType, isWhite: Props['isWhite']) => {
    if (checkType === CheckType.alert) {
      return (
        <ReactSVG
          style={
            isWhite
              ? { ...iconStyle, filter: 'brightness(0) invert(1)' }
              : iconStyle
          }
          src={alertIcon}
        />
      )
    }
    if (checkType === CheckType.fail) {
      return (
        <ReactSVG
          style={
            isWhite
              ? { ...iconStyle, filter: 'brightness(0) invert(1)' }
              : iconStyle
          }
          src={failIcon}
        />
      )
    }
    if (checkType === CheckType.success) {
      return (
        <ReactSVG
          style={
            isWhite
              ? { ...iconStyle, filter: 'brightness(0) invert(1)' }
              : iconStyle
          }
          src={successIcon}
        />
      )
    }
  }
  return (
    <CheckWrapper isWhite={isWhite}>
      {getCheckIcon(checkType, isWhite)}
      <CheckText isWhite={isWhite} checkType={checkType}>
        {children}
      </CheckText>
    </CheckWrapper>
  )
}

export default ShipmentCheck
