import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AgentRoute from './routes/agent'
import CarrierRoute from './routes/carrier/carrier'
import CreateShipmentRoute from './routes/carrier/createShipment'
import LoginRoute from './routes/login'
import { withTracker } from './withTracker'
import AccreditationKeysRoute from './routes/accreditation/keys'
import AccreditationRoute from './routes/accreditation'
import AccreditationDetailRoute from './routes/accreditation/detail'

function App() {
  return (
    <div className="App">
      <Switch>
        {/* Agent Routes */}
        <Route path="/agent" component={withTracker(AgentRoute)} />

        {/* Carrier Routes */}
        <Route path="/carrier/createShipment" component={CreateShipmentRoute} />
        <Route path="/carrier" component={withTracker(CarrierRoute)} />

        {/* Accreditation org. Routes */}
        <Route path="/accreditation/keys" component={AccreditationKeysRoute} />
        <Route
          path="/accreditation/:slug"
          component={AccreditationDetailRoute}
        />
        <Route
          path="/accreditation"
          component={withTracker(AccreditationRoute)}
        />

        {/* Login Route */}
        <Route path="/" component={withTracker(LoginRoute)} />
      </Switch>
    </div>
  )
}

export default App
