import React, { useState, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

import IconTitle from '../iconTitle'
import shipmentActive from '../../images/icons/shipment-active.svg'
import { colors } from '../../vars'
import ShipmentCard from './shipmentCard'
import { Link } from 'react-router-dom'
import { Disclaimer } from '../login'

interface Props {
  shipmentData?: any
}

const ShipmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`

const Shipments: React.FunctionComponent<Props> = ({ shipmentData }) => {
  const openShipments = shipmentData ? shipmentData.filter(shipment => shipment.status === 'OPEN' && shipment.checkpoints.length > 0) : []
  const signedShipments = shipmentData ? shipmentData.filter(shipment => shipment.status !== 'OPEN' && shipment.checkpoints.length > 0): []
  console.log('shipmentData', shipmentData)
  return (
    <>
      <IconTitle
        icon={
          <ReactSVG
            src={shipmentActive}
            style={{ height: 20 }}
            color={colors.green}
          />
        }
      >
        Manage shipments
      </IconTitle>
      <h3>Open</h3>
      <ShipmentsWrapper>
        {openShipments.map(shipmentObj => {
          const from = shipmentObj.checkpoints[0].country.name;
          const to = shipmentObj.checkpoints[shipmentObj.checkpoints.length - 1].country.name;

          return (
            <Link to={`/accreditation/${shipmentObj.id}`}>
              <ShipmentCard
                from={from}
                to={to} />
            </Link>
          )
        })}
        {/* <Link to="/accreditation/Italy-Germany">
          <ShipmentCard from={'Italy'} to={'Germany'} />
        </Link>
        <Link to="/accreditation/France-Czechia">
          <ShipmentCard from={'France'} to={'Czechia'} />
        </Link> */}
      </ShipmentsWrapper>
      <h3>Signed</h3>
      <ShipmentsWrapper>
        {signedShipments.map(shipmentObj => {
          const from = shipmentObj.checkpoints[0].country.name;
          const to = shipmentObj.checkpoints[shipmentObj.checkpoints.length - 1].country.name;

          return (
            <Link to={`/accreditation/${shipmentObj.id}`}>
              <ShipmentCard
                from={from}
                to={to} />
            </Link>
        )})}
        {/* <ShipmentCard noDots from={'Romania'} to={'Germany'} />
        <ShipmentCard noDots from={'Spain'} to={'Netherlands'} />
        <ShipmentCard noDots from={'Poland'} to={'Romania'} /> */}
      </ShipmentsWrapper>
      <Disclaimer>
        This is not live data and the actions are not functional yet. You can
        however test the accreditation-flow by clicking one of the "open"
        shipments.
      </Disclaimer>
    </>
  )
}

export default Shipments
