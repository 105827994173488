import React, { useState, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'
import useAxios from 'axios-hooks'

import IconTitle from '../iconTitle'
import shipmentActive from '../../images/icons/shipment-active.svg'
import { colors } from '../../vars'
import ShipmentCard from './shipmentCard'
import { useHistory } from 'react-router-dom'
import { Timeline, Button } from 'antd'
import { API_URL } from '../../consts/index';

interface Props {
  shipmentId: string,
  shipmentData: any,
}

const DetailList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: auto;
`
const Detail = styled.div``
const DetailField = styled.span`
  font-weight: 500;
`

const PassingCountries = styled(Timeline)`
  margin: 15px 0;
  .ant-timeline-item-last {
    padding-bottom: 0;
  }
  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: unset;
  }
`

const AccreditButton = styled(Button)`
  margin-top: auto;
  margin-bottom: 25px;
  border-radius: 4px;
  height: 50px;
  border: none;
  &.ant-btn-primary {
    background-color: ${colors.green};
  }
`

const DeclineButton = styled(AccreditButton)`
  margin-top: 0;
  &.ant-btn-primary {
    background-color: ${colors.red};
  }
`
const MoveButton = styled(AccreditButton)`
  margin-top: 0;
  &.ant-btn-primary {
    background-color: ${colors.orange};
  }
`

const ShipmentDetail: React.FunctionComponent<Props> = ({ shipmentId, shipmentData }) => {
  const shipmentRoute = shipmentId.split('-')
  const history = useHistory();

  const [status, setStatus] = useState('')
  const [
    { data: getData, loading: getLoading, error: getError },
    exectutePUT,
  ] = useAxios({
    url: `${API_URL}/declarations/${shipmentId}/status/${status}`,
    method: 'PUT'
  },
   { manual: true }
  )

  useEffect(() => {
    if (status !== '') {
      exectutePUT().then(() => {
        history.push('/accreditation')
      })
    }
  }, [status])

  if (!shipmentData) return <div>No Data</div>
  
  const destinationCountry = shipmentData.checkpoints[shipmentData.checkpoints.length - 1].country.name;

  // This way of parsing the shipmentID is just for the demo's sake - actual data will be fetched from the backend using the shipmentId which is a number
  return (
    <>
      <IconTitle
        icon={
          <ReactSVG
            src={shipmentActive}
            style={{ height: 20 }}
            color={colors.green}
          />
        }
      >
        Sign/Edit Shipment
      </IconTitle>
      <h3>Shipment details</h3>
      <ShipmentCard noDots
        from={shipmentData.checkpoints[0].country.name}
        to={destinationCountry} />
      <DetailList>
        <Detail>
          Sender name: <DetailField>{shipmentData.sender && shipmentData.sender.name}</DetailField>
        </Detail>
        <Detail>
          Consignee Name: <DetailField>{shipmentData.consignee && shipmentData.consignee.name}</DetailField>
        </Detail>
        <Detail>
          Carrier Name: <DetailField>{shipmentData.carrier && shipmentData.carrier.name}</DetailField>
        </Detail>
        <Detail>
          Is marked as high priority: <DetailField>{shipmentData.priority ? 'Yes' : 'No'}</DetailField>
        </Detail>
        <Detail>
          Number Plate: <DetailField>{shipmentData.licensePlate}</DetailField>
        </Detail>
        <Detail>
          Passing Countries:{' '}
            {shipmentData.checkpoints.length > 2 ?
              <PassingCountries>
                {shipmentData.checkpoints
                  .slice(1, shipmentData.checkpoints.length - 1)
                  .map(data => <Timeline.Item>{data.country.name}</Timeline.Item>)}
              </PassingCountries>
              : ''}
        </Detail>
        <Detail>
          Destination Country: <DetailField>{destinationCountry}</DetailField>
        </Detail>
      </DetailList>
      {/* <Link to="/accreditation"> */}
        <AccreditButton type="primary" block
          onClick={() => setStatus('CLOSED_WITH_SUCCESS')}>
          Accredit shipment
        </AccreditButton>
      {/* </Link>
      <Link to="/accreditation"> */}
        <DeclineButton type="primary" block
          onClick={() => setStatus('CLOSED_WITH_ERROR')}>
          Decline accreditation
        </DeclineButton>
        <MoveButton type="primary" block
          onClick={() => setStatus('OPEN')}>
          Move to Open
        </MoveButton>
      {/* </Link> */}
    </>
  )
}

export default ShipmentDetail
